<template>
  <div>
    <!-- Modal xem thông tin vé -->
    <CommonModal
      title="Thanh toán"
      :isOpen="isOpenModalConfirmPayment"
      :cancleTextBtn="paymentConfirm[isEnoughMoney ? 'payment' : 'deposit'].cancle"
      :confirmTextBtn="isUseRewardBalance ? 'Đồng ý' : paymentConfirm[isEnoughMoney ? 'payment' : 'deposit'].confirm"
      @updateOpen="setModalConfirmPayment"
      @handleConfirm="handleConfirm"
      :isConfirmBtnLoading="isLoading"
      :isCloseWhenConfirm="false"
      contentClass="overflowInit"
      isBodyTooltip
      dataStepBody="show_popup_payment"
      contentBodyTooltip="Quý khách cần kiểm tra vé đã chọn và tổng số tiền thanh toán. Nhấn nút <b>Thanh Toán</b> để xác nhận thanh toán."
      positionBodyTooltip="bottom"
    >
      <div class="groupInfoConfirm">
        <p class="mb-2"><i>Xác thực thanh toán cho vé số đã chọn:</i></p>
        <div
          v-if="
            paymentConfirmData.listProduct &&
              paymentConfirmData.listProduct.length > 0 &&
              !paymentConfirmData.listProduct[0].isFeedKeno
          "
          class="groupInfoConfirm__listProduct"
        >
          <div v-for="(product, index) in paymentConfirmData.listProduct" :key="index" class="groupInfoConfirm__price">
            <div v-if="product.isTraditional">
              <b>
                Vé số kiến thiết <span class="text-capitalize">{{ product.productLabel }}</span>
              </b>
              <div>
                <em>
                  {{ getDay(product.lotteryTime) }}
                  , ngày
                  {{ formatDate(product.lotteryTime) }} (<span v-if="product.isGroup">CN </span
                  ><strong>{{ product.numberTicketPayment }}</strong> vé)
                </em>
              </div>

              <!-- <div class="groupInfoConfirm__numberTicket">({{ product.numberTicketPayment }} bộ số)</div> -->
            </div>
            <div v-else>
              <b v-if="product.nameGame === 'KENO' && !product.isCombo">
                {{ product.nameGame }}
              </b>
              <b v-else-if="product.nameGame && product.productLabel">
                {{ `${product.nameGame} - ${product.productLabel}` }}
              </b>
              <b v-else>
                {{ product.nameGame || product.productLabel }}
              </b>
              <div v-if="product.customSubData" class="groupInfoConfirm__numberTicket">{{ product.customSubData }}</div>
              <div v-else class="groupInfoConfirm__numberTicket">({{ product.numberTicketPayment }} bộ số)</div>
            </div>
            <span>{{ formatPrice(product.totalPrice) }}</span>
          </div>
        </div>
        <div
          v-else-if="
            paymentConfirmData.listProduct &&
              paymentConfirmData.listProduct.length > 0 &&
              paymentConfirmData.listProduct[0].isFeedKeno
          "
          class="groupInfoConfirm__listProduct"
        >
          <div
            v-for="(product, index) in paymentConfirmData.listProduct[0].listItem"
            :key="index"
            class="groupInfoConfirm__price"
          >
            <div>
              <b>
                {{ `Nuôi Keno bậc ${product.listNumberActive.length}` }}
              </b>
              <div class="groupInfoConfirm__numberTicket">({{ product.periodNumber }} kỳ)</div>
            </div>
            <span>{{ formatPrice(product.total) }}</span>
          </div>
        </div>
        <div class="groupInfoConfirm__info">
          <p class="groupInfoConfirm__fee">
            <span>
              Phí giao dịch
            </span>
            <span>{{ formatPrice(paymentConfirmData.transactionFee) }}</span>
          </p>
          <hr />
          <p class="groupInfoConfirm__total">
            <span> Tổng cộng </span>
            <span class="m-text-primary">{{ formatPrice(paymentConfirmData.resultPrice) }}</span>
          </p>
          <p v-if="isComboKeno" class="groupInfoConfirm__warning d-block text-left">
            Lưu ý: khi mua bao Keno với số lượng bộ số lớn có thể in thiếu vé do máy Vietlott.
          </p>

          <p v-if="isUseRewardBalance" class="groupInfoConfirm__warning d-block text-left">
            Số dư Tài khoản mua vé không đủ để thanh toán. Bạn có muốn sử dụng thêm
            <strong>{{ this.formatPrice(paymentConfirmData.resultPrice - depositBalance) }}</strong> từ tài khoản thưởng
            để tiếp tục thanh toán?
          </p>
          <div
            v-else
            class="groupInfoConfirm__warning"
            :class="{
              'd-block': !isEnoughMoney
            }"
            v-show="!isEnoughMoney"
          >
            Số dư tài khoản mua vé của bạn là <b>{{ this.formatPrice(depositBalance + pendingBalance) }}</b>
            <!-- <br /> -->
            <div v-if="pendingBalance">
              (bao gồm {{ this.formatPrice(pendingBalance) }} đang tạm giữ từ đơn mua vé). Vui lòng nạp tiền để thanh
              toán.
            </div>

            <div v-else>Vui lòng nạp tiền để thanh toán.</div>
          </div>
          <p v-if="isGiftOrder" class="groupInfoConfirm__warning d-block text-left">
            Bạn đang thanh toán cho vé tặng, người được tặng sẽ sở hữu vé này.
          </p>
        </div>
      </div>
    </CommonModal>
  </div>
</template>

<script>
import CommonModal from '@/components/CommonModal';
import { mapGetters } from 'vuex';

export default {
  components: {
    CommonModal
    // Tooltip
  },
  props: {
    paymentConfirmData: {
      type: Object
    },
    shipData: {
      type: Object
    },
    paymentData: {
      type: Object
    },
    handleConfirmMessagePayment: Function,
    isEnoughMoney: Boolean,
    isLoading: Boolean,
    isUseRewardBalance: Boolean
  },
  computed: {
    ...mapGetters(['getterDataRequestPayment']),
    isGiftOrder() {
      return !!this.getterDataRequestPayment.gift_code;
    },
    isComboKeno() {
      return this.paymentConfirmData.listProduct.some((item) => item.isCombo);
    }
  },
  data() {
    return {
      isOpenModalConfirmPayment: false,

      paymentConfirm: {
        payment: {
          cancle: 'Đóng',
          confirm: 'Thanh toán'
        },
        deposit: {
          cancle: 'Đóng',
          confirm: 'Nạp tiền'
        }
      }
    };
  },
  methods: {
    setModalConfirmPayment(newVal) {
      this.isOpenModalConfirmPayment = newVal;
      if (!newVal) {
        this.runPlayTrial('show_popup_payment', false);
      }
    },
    handleConfirm() {
      this.$emit('handleConfirmMessagePayment');
    }
  }
};
</script>

<style lang="scss" scoped>
.groupInfoConfirm {
  hr {
    margin: 10px 0 7.5px;
    border: solid 1px #cecece;
  }
  &__info {
    position: sticky;
    bottom: 0;
    left: 0;
    padding-top: 5px;
    background-color: #ffff;

    & > p {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
  &__price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__total {
    margin-bottom: 0px !important;
    span {
      font-weight: bold;
    }
  }
  &__warning {
    text-align: center;
    font-style: italic;
    color: $primaryColor;

    margin-top: 20px;
  }

  &__numberTicket {
    font-size: 0.8125rem;
    font-style: italic;
  }
}

// .color-black{
//   color: #000000 !important
// }
</style>
