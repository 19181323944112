<template>
  <div class="pointer" @click.stop="updatedData">
    <div
      class="groupCheckbox"
      :class="{
        labelRight: labelRight,
        [[groupCheckboxClass]]: !!groupCheckboxClass
      }"
    >
      <div class="checkbox-custom-input-wrapper" :class="{ checked: value, sm: small }">
        <input
          class="checkbox-hidden-input"
          :class="{ 'checkbox-custom-input-wrapper--checked': value }"
          type="checkbox"
          :checked="value"
        />
        <span v-if="value" class="checkbox-custom-input" :class="{ 'checkbox-custom-input--checked': value }">
          &#x2713;
          <!-- tickmark -->
        </span>
      </div>
      <label class="w-100 pointer d-flex align-items-center">
        {{ label }}
        <slot />
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String
    },
    groupCheckboxClass: {
      type: String
    },
    labelRight: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updatedData() {
      this.$emit('updateCheckBox', !this.value);
    }
  }
};
</script>
<style lang="scss" scoped>
.groupCheckbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  label {
    margin: 0;
  }

  .checkbox-custom-input-wrapper {
    position: absolute;
    right: 0;
    border: 1px solid #333333;
    &.sm {
      height: 15px;
      width: 15px;
    }

    &.checked {
      border-bottom: none;
      &.sm {
        font-size: 13px;
      }
    }
  }
  &.labelRight {
    padding-left: 25px;
    .checkbox-custom-input-wrapper {
      left: 0;
    }
  }
}
</style>
