var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CommonModal',{attrs:{"title":"Thanh toán","isOpen":_vm.isOpenModalConfirmPayment,"cancleTextBtn":_vm.paymentConfirm[_vm.isEnoughMoney ? 'payment' : 'deposit'].cancle,"confirmTextBtn":_vm.isUseRewardBalance ? 'Đồng ý' : _vm.paymentConfirm[_vm.isEnoughMoney ? 'payment' : 'deposit'].confirm,"isConfirmBtnLoading":_vm.isLoading,"isCloseWhenConfirm":false,"contentClass":"overflowInit","isBodyTooltip":"","dataStepBody":"show_popup_payment","contentBodyTooltip":"Quý khách cần kiểm tra vé đã chọn và tổng số tiền thanh toán. Nhấn nút <b>Thanh Toán</b> để xác nhận thanh toán.","positionBodyTooltip":"bottom"},on:{"updateOpen":_vm.setModalConfirmPayment,"handleConfirm":_vm.handleConfirm}},[_c('div',{staticClass:"groupInfoConfirm"},[_c('p',{staticClass:"mb-2"},[_c('i',[_vm._v("Xác thực thanh toán cho vé số đã chọn:")])]),(
          _vm.paymentConfirmData.listProduct &&
            _vm.paymentConfirmData.listProduct.length > 0 &&
            !_vm.paymentConfirmData.listProduct[0].isFeedKeno
        )?_c('div',{staticClass:"groupInfoConfirm__listProduct"},_vm._l((_vm.paymentConfirmData.listProduct),function(product,index){return _c('div',{key:index,staticClass:"groupInfoConfirm__price"},[(product.isTraditional)?_c('div',[_c('b',[_vm._v(" Vé số kiến thiết "),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(product.productLabel))])]),_c('div',[_c('em',[_vm._v(" "+_vm._s(_vm.getDay(product.lotteryTime))+" , ngày "+_vm._s(_vm.formatDate(product.lotteryTime))+" ("),(product.isGroup)?_c('span',[_vm._v("CN ")]):_vm._e(),_c('strong',[_vm._v(_vm._s(product.numberTicketPayment))]),_vm._v(" vé) ")])])]):_c('div',[(product.nameGame === 'KENO' && !product.isCombo)?_c('b',[_vm._v(" "+_vm._s(product.nameGame)+" ")]):(product.nameGame && product.productLabel)?_c('b',[_vm._v(" "+_vm._s(((product.nameGame) + " - " + (product.productLabel)))+" ")]):_c('b',[_vm._v(" "+_vm._s(product.nameGame || product.productLabel)+" ")]),(product.customSubData)?_c('div',{staticClass:"groupInfoConfirm__numberTicket"},[_vm._v(_vm._s(product.customSubData))]):_c('div',{staticClass:"groupInfoConfirm__numberTicket"},[_vm._v("("+_vm._s(product.numberTicketPayment)+" bộ số)")])]),_c('span',[_vm._v(_vm._s(_vm.formatPrice(product.totalPrice)))])])}),0):(
          _vm.paymentConfirmData.listProduct &&
            _vm.paymentConfirmData.listProduct.length > 0 &&
            _vm.paymentConfirmData.listProduct[0].isFeedKeno
        )?_c('div',{staticClass:"groupInfoConfirm__listProduct"},_vm._l((_vm.paymentConfirmData.listProduct[0].listItem),function(product,index){return _c('div',{key:index,staticClass:"groupInfoConfirm__price"},[_c('div',[_c('b',[_vm._v(" "+_vm._s(("Nuôi Keno bậc " + (product.listNumberActive.length)))+" ")]),_c('div',{staticClass:"groupInfoConfirm__numberTicket"},[_vm._v("("+_vm._s(product.periodNumber)+" kỳ)")])]),_c('span',[_vm._v(_vm._s(_vm.formatPrice(product.total)))])])}),0):_vm._e(),_c('div',{staticClass:"groupInfoConfirm__info"},[_c('p',{staticClass:"groupInfoConfirm__fee"},[_c('span',[_vm._v(" Phí giao dịch ")]),_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.paymentConfirmData.transactionFee)))])]),_c('hr'),_c('p',{staticClass:"groupInfoConfirm__total"},[_c('span',[_vm._v(" Tổng cộng ")]),_c('span',{staticClass:"m-text-primary"},[_vm._v(_vm._s(_vm.formatPrice(_vm.paymentConfirmData.resultPrice)))])]),(_vm.isComboKeno)?_c('p',{staticClass:"groupInfoConfirm__warning d-block text-left"},[_vm._v(" Lưu ý: khi mua bao Keno với số lượng bộ số lớn có thể in thiếu vé do máy Vietlott. ")]):_vm._e(),(_vm.isUseRewardBalance)?_c('p',{staticClass:"groupInfoConfirm__warning d-block text-left"},[_vm._v(" Số dư Tài khoản mua vé không đủ để thanh toán. Bạn có muốn sử dụng thêm "),_c('strong',[_vm._v(_vm._s(this.formatPrice(_vm.paymentConfirmData.resultPrice - _vm.depositBalance)))]),_vm._v(" từ tài khoản thưởng để tiếp tục thanh toán? ")]):_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEnoughMoney),expression:"!isEnoughMoney"}],staticClass:"groupInfoConfirm__warning",class:{
            'd-block': !_vm.isEnoughMoney
          }},[_vm._v(" Số dư tài khoản mua vé của bạn là "),_c('b',[_vm._v(_vm._s(this.formatPrice(_vm.depositBalance + _vm.pendingBalance)))]),(_vm.pendingBalance)?_c('div',[_vm._v(" (bao gồm "+_vm._s(this.formatPrice(_vm.pendingBalance))+" đang tạm giữ từ đơn mua vé). Vui lòng nạp tiền để thanh toán. ")]):_c('div',[_vm._v("Vui lòng nạp tiền để thanh toán.")])]),(_vm.isGiftOrder)?_c('p',{staticClass:"groupInfoConfirm__warning d-block text-left"},[_vm._v(" Bạn đang thanh toán cho vé tặng, người được tặng sẽ sở hữu vé này. ")]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }