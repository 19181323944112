<template>
  <div>
    <!-- Modal nhập thông tin vé -->
    <CommonModal
      title="Thông tin chủ vé"
      :isOpen="isOpenModalInfo"
      @updateOpen="setModalInfo"
      @handleConfirm="handleConfirm"
      :isCloseWhenConfirm="false"
      :isConfirmBtnDisable="isDisabled"
      :isCancleBtn="false"
      confirmTextBtn="Tiếp tục"
      footerClass="customConfirmBtn"
      dialogClass="modalPaymentInfoCustom"
      :isScrollable="false"
      :isNoCloseOnBackdrop="true"
      :isNoCloseOnEsc="true"
    >
      <form ref="form">
        <div class="row no-gutters">
          <div class="col-12">
            <b-form-group>
              <label>Số điện thoại</label>
              <b-form-input
                v-validate="{ min: 10, max: 10, regex: /(03|07|08|09|05)+([0-9]{8})\b/, required: true }"
                :class="{ input: true, 'is-danger': errors.has('phoneNumber'), customInputWithDraw: true }"
                ref="inputRegister"
                name="phoneNumber"
                v-model="phoneNumber"
                id="phoneNumberInput"
                data-vv-as="Số điện thoại"
                type="number"
                pattern="[0-9]*"
              ></b-form-input>

              <span v-show="errors.has('phoneNumber')" class="is-danger-text position-relative">
                {{ errors.first('phoneNumber') }}
              </span>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group>
              <label>Số CMND/CCCD</label>
              <b-form-input
                v-validate="{ required: true, regex: /^([0-9]{9}|[0-9]{12})$/ }"
                class="customInputWithDraw"
                v-model="passportId"
                data-vv-as="Số CMND/CCCD"
                :class="{ input: true, 'is-danger': errors.has('passportId') }"
                id="passportId"
                name="passportId"
              ></b-form-input>
              <span v-if="errors.has('passportId') && hasErrorRule('passportId', 'required')" class="is-danger-text"
                >Vui lòng nhập Số CMND/CCCD</span
              >
              <span v-if="errors.has('passportId') && hasErrorRule('passportId', 'regex')" class="is-danger-text"
                >Số CMND/CCCD phải có 9 hoặc 12 số</span
              >
            </b-form-group>
          </div>
          <div class="col-12 ">
            <p class="font-14 mb-4 pb-1">
              <i>
                <b class="m-text-primary">
                  Lưu ý:
                </b>
                vui lòng nhập chính xác thông tin, đây là căn cứ để liên hệ và xác minh khi nhận thưởng.
              </i>
            </p>
            <CommonCheckBox
              :value="isConfirmInfo"
              label="Xác nhận thông tin trên là chính xác."
              @updateCheckBox="updatedConfirmInfo"
              class="font-14"
            />
          </div>
        </div>
      </form>
    </CommonModal>
  </div>
</template>

<script>
import CommonModal from '@/components/CommonModal';
import CommonCheckBox from '@/components/CommonCheckBox';

export default {
  components: {
    CommonModal,
    CommonCheckBox
  },
  props: {
    handleNextStep: Function
  },
  data() {
    return {
      isOpenModalInfo: false,

      isConfirmInfo: false
    };
  },
  computed: {
    isDisabled() {
      return this.errors.items.length > 0 || !this.passportId || !this.phoneNumber || !this.isConfirmInfo;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    parentUserInfo() {
      return this.$store.state.parentUserInfo;
    },
    passportId: {
      get() {
        return this.$store.getters.getterQuickBuyInfo.passportId;
      },
      set(newVal) {
        this.$store.commit('setModalQuickBuyInfo', {
          phoneNumber: this.phoneNumber,
          passportId: newVal
        });
      }
    },
    phoneNumber: {
      get() {
        return this.$store.getters.getterQuickBuyInfo.phoneNumber;
      },
      set(newVal) {
        this.$store.commit('setModalQuickBuyInfo', {
          passportId: this.passportId,
          phoneNumber: newVal
        });
      }
    }
  },
  methods: {
    setModalInfo(newVal) {
      this.isOpenModalInfo = newVal;
    },
    handleConfirm() {
      this.setModalInfo(false);

      this.$emit('handleNextStep', {
        passportId: this.passportId,
        phoneNumber: this.phoneNumber
      });
    },
    hasErrorRule(field, rule) {
      return this.errors.items.find((item) => item.field == field && item.rule == rule);
    },
    updatedConfirmInfo(newValue) {
      this.isConfirmInfo = newValue;
    }
  },
  watch: {
    isOpenModalInfo: function(newValue) {
      if (newValue) {
        const { phone, passport } = this.loggedIn ? this.userInfo : this.parentUserInfo;
        if (phone && !this.phoneNumber) {
          this.phoneNumber = phone;
        }
        if (passport && !this.passportId) {
          this.passportId = passport;
        }

        if (this.loggedIn) {
          this.handleConfirm();
        } else {
          if (phone || passport) {
            this.$nextTick(function() {
              this.$validator.validateAll();
            });
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.groupInfoConfirm {
  // padding: 0 0.25rem;
  hr {
    margin: 10px 0 7.5px;
    border: solid 1px #cecece;
  }
  &__info {
    position: sticky;
    bottom: 0;
    left: 0;
    padding-top: 5px;
    background-color: #ffff;

    & > p {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
  &__price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__total {
    margin-bottom: 0px !important;
    span {
      font-weight: bold;
    }
  }
  &__warning {
    text-align: center;
    font-style: italic;
    color: #790505;

    margin-top: 20px;
  }

  &__numberTicket {
    font-size: 0.8125rem;
    font-style: italic;
  }
}
</style>

<style lang="scss">
.customConfirmBtn {
  button.actionGroup__btn--confirm {
    max-width: 100% !important;
  }
}

.modalPaymentInfoCustom {
  max-width: 344px !important;
}
</style>
