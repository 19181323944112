<template>
  <div>
    <!-- Modal thanh toán thành công -->
    <CommonModal
      title="Thanh toán"
      :isOpen="isOpenModalPayment"
      :fullHeight="true"
      :dialogClass="isPaymentSuccess ? 'modalPaymentSuccessCustom' : 'modalPaymentCustomNew'"
      :isNoCloseOnBackdrop="true"
      :isNoCloseOnEsc="true"
      @updateOpen="setOpenModalPayment"
      :confirmTextBtn="isPhoneExist ? 'Đăng nhập' : 'Đăng ký'"
      @handleConfirm="handleConfirm"
      @handleHide="handleCloseModal"
      :isConfirmBtn="isPaymentSuccess"
      :isCancleBtn="false"
      :isHiddenFooter="!isPaymentSuccess || loggedIn"
      :footerClass="{
        customConfirmBtn: true,
        customPaymentSuccess: isPaymentSuccess
      }"
    >
      <div v-if="isPaymentSuccess" class="modalPaymentSuccess">
        <div class="modalPaymentSuccess__description text-center">
          <img
            src="@/assets/img/icon/icon_result-success.svg"
            alt="icon success"
            width="46px"
            height="46px"
            draggable="false"
          />
          <p>Thanh toán thành công</p>
          <p>Mã thanh toán: #{{ paymentSuccessData.display_order_id }}</p>
        </div>

        <div
          class="pb-3"
          :class="{
            borderBottomDash: !loggedIn
          }"
        >
          <div class="d-flex">
            <p class="font-18 mb-0">Thông tin chủ vé</p>
          </div>
          <div class="boxTicketRecipientInfo">
            <div class="recipientInfo d-flex mb-1">
              <div>Số điện thoại</div>
              <div>{{ phoneNumber }}</div>
            </div>
            <hr class="my-1" />
            <div class="recipientInfo d-flex">
              <div>Số CMND/CCCD</div>
              <div>{{ passportId }}</div>
            </div>
          </div>
          <div v-if="!loggedIn" class="mt-1">
            <p class="mb-1">Email nhận thông báo trúng thưởng</p>
            <b-form-input
              v-validate="{ regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }"
              :class="{ input: true, 'is-danger': errors.has('email'), customInputWithDraw: true }"
              name="email"
              v-model="email"
              id="email"
              data-vv-as="Email"
              :disabled="isUpdateEmail"
            ></b-form-input>
            <span v-show="errors.has('email')" class="is-danger-text position-relative">
              {{ errors.first('email') }}
            </span>
            <button
              v-if="!isUpdateEmail"
              class="btn btnUpdateEmail mt-3"
              :disabled="errors.has('email') || !email || isLoadingUpdateEmail"
              @click="handleUpdateEmail"
            >
              <b-spinner v-if="isLoadingUpdateEmail" small variant="light"></b-spinner>
              <span v-else>Cập nhật</span>
            </button>
            <p v-else class="mt-3 mb-1">
              <i>
                Thông tin vé mua đã được gửi về email.
              </i>
            </p>
            <button
              v-if="isGiftOrder && isUpdateEmail && isShowShareFB"
              @click="$store.commit('setIsOpenModalShare', true)"
              class="btn font-15 text-uppercase w-100 buttonShareFb"
            >
              Chia sẻ
            </button>
          </div>
        </div>
        <div v-if="!loggedIn">
          <p v-if="isPhoneExist" class="text-center">
            Hệ thống nhận thấy số điện thoại mua vé đã có tài khoản. Bạn có muốn đăng nhập để liên kết và quản lý vé đã
            mua?
          </p>
          <p v-else class="text-center">Bạn có thể tạo tài khoản để quản lý vé đã mua dễ dàng hơn</p>
        </div>
      </div>
      <div v-else>
        <div class="groupQrPaymnet">
          <div>
            <div class="groupQrPaymnet__left text-center">
              <p>Số tiền thanh toán</p>
              <p class="totalPayment">
                <b>{{ formatPrice(paymentConfirmData.resultPrice) }}</b>
              </p>
            </div>
          </div>
          <div class="d-flex flex-column flex-lg-row">
            <div class="groupQrPaymnet__left">
              <div class="qrCode">
                <div v-if="isLoadingQrPayment">
                  <content-placeholders>
                    <content-placeholders-img class="placeholders-qr" />
                  </content-placeholders>
                </div>
                <QrcodeVue
                  id="qrCodePayment"
                  v-else
                  :value="paymentQrCode"
                  class="text-center"
                  size="244"
                  :level="'H'"
                />
              </div>
              <p v-if="!isLoadingQrPayment" class="mb-3 pb-1 textNoWrap">
                <i v-if="countDownExpiredQrString">
                  Mã thanh toán sẽ hết hạn sau <b>{{ countDownExpiredQrString }}</b>
                </i>
                <i v-else> Mã thanh toán sẽ hết hạn sau hơn <b>5 phút</b> </i>
              </p>
              <button
                v-if="!isLoadingQrPayment"
                class="btn btnDownloadQr"
                @click="handleDownloadQr('#qrCodePayment > canvas', handler)"
              >
                <img src="@/assets/img/icon/icon_download.svg" alt="icon download" width="17px" height="15px" />
                Tải mã QR
              </button>
            </div>
            <div class="groupQrPaymnet__right">
              <div v-if="!loggedIn" class="mb-4">
                <div class="d-flex">
                  <p class="font-18 mb-0">Thông tin chủ vé</p>
                </div>
                <div class="boxTicketRecipientInfo">
                  <div class="recipientInfo d-flex mb-1">
                    <div>Số điện thoại</div>
                    <div>{{ phoneNumber }}</div>
                  </div>
                  <hr class="my-1" />
                  <div class="recipientInfo d-flex">
                    <div>Số CMND/CCCD</div>
                    <div>{{ passportId }}</div>
                  </div>
                </div>
              </div>
              <div class="tutorialQr">
                <h5>Hướng dẫn thanh toán</h5>
                <ul>
                  <li>Đăng nhập ứng dụng Mobile Banking, chọn chức năng QRPay và quét mã QR.</li>
                  <li>Kiểm tra thông tin đơn hàng và lựa chọn tài khoản thanh toán.</li>
                  <li>Xác nhận thanh toán và hoàn tất giao dịch.</li>
                </ul>
                <p class="listBankSupport" @click="handleOpenModalQRPay(true)">Danh sách ngân hàng hỗ trợ QR Pay</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
    <ModalListBankSupportQRPay :isOpenModalQRPay="isOpenModalQRPay" @handleOpenModalQRPay="handleOpenModalQRPay" />
  </div>
</template>

<script>
import CommonModal from '@/components/CommonModal';
// import srcLogo from '../../assets/img/vietlott-power.png';

import QrcodeVue from 'qrcode.vue';
// import { AwesomeQR } from '../../js/awesomeQr/awesome-qr';
import ModalListBankSupportQRPay from '@/components/Payment/ModalListBankSupportQRPay';
import AccountService from '../../api/account';

import { SAFE_TIME_QR } from '../../common/global';
import OrderService from '../../api/order';
import { Noti } from '../../main';
import { mapGetters } from 'vuex';
const Swal = require('sweetalert2');

export default {
  props: [
    'handler',
    'listPaymentMethod',
    'paymentConfirmData',
    'nearestPeriodChoose',
    'paymentDataQuickBuy',
    'gameData',
    'isPaymentSuccess',
    'paymentSuccessData',
    'isLoadingQrPayment',
    'paymentQrCode'
  ],
  components: {
    CommonModal,
    ModalListBankSupportQRPay,
    QrcodeVue
  },

  data() {
    return {
      isOpenModalPayment: false,
      passportId: '',
      phoneNumber: '',
      email: '',
      imgSrc: '',
      isOpenModalQRPay: false,
      isPhoneExist: false,
      isUpdateEmail: false,
      countDownExpiredQrCode: 0,
      timerId: null,
      isLoadingUpdateEmail: false
    };
  },
  computed: {
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    getMegaTimer() {
      return this.$store.state.CD.megaHomeTimer;
    },
    getPowerTimer() {
      return this.$store.state.CD.powerHomeTimer;
    },
    getMax3DTimer() {
      return this.$store.state.CD.max3DHomeTimer;
    },
    getMax3DProTimer() {
      return this.$store.state.CD.max3DProHomeTimer;
    },
    getMax4DTimer() {
      return this.$store.state.CD.max4DHomeTimer;
    },
    calcSafeTime() {
      const { metadata } = this.gameData;
      const metadataObj = JSON.parse(metadata);

      return this.countDownExpiredQrCode - SAFE_TIME_QR - metadataObj?.count_down || 0;
    },
    countDownExpiredQrString() {
      if (this.calcSafeTime < 0) {
        return '';
      }

      let hours = Math.floor((this.calcSafeTime % (60 * 60 * 24)) / (60 * 60));
      let minutes = Math.floor((this.calcSafeTime % (60 * 60)) / 60);
      let seconds = Math.floor(this.calcSafeTime % 60);

      if (hours > 0 || (minutes > 4 && seconds >= 0)) return '';
      if (minutes > 0) {
        return `${this.padStart(minutes + '', 2, '0')}:${this.padStart(seconds + '', 2, '0')}`;
      } else {
        return `${seconds}s`;
      }
    },
    getLoadByIframe() {
      return this.$store.state.loadByIframe;
    },
    getParentInviteCode() {
      return this.$store.state.parentInviteCode;
    },
    isConnectedSocket() {
      return this.$store.getters.getterIsConnectedSocket;
    },
    comPassportId: {
      get() {
        return this.$store.getters.getterQuickBuyInfo.passportId;
      },
      set(newVal) {
        this.$store.commit('setModalQuickBuyInfo', {
          phoneNumber: this.comPhoneNumber,
          passportId: newVal
        });
      }
    },
    comPhoneNumber: {
      get() {
        return this.$store.getters.getterQuickBuyInfo.phoneNumber;
      },
      set(newVal) {
        this.$store.commit('setModalQuickBuyInfo', {
          passportId: this.comPassportId,
          phoneNumber: newVal
        });
      }
    },
    parentUserInfo() {
      return this.$store.state.parentUserInfo;
    },
    ...mapGetters(['getterDataRequestPayment']),
    isGiftOrder() {
      return !!this.getterDataRequestPayment.gift_code;
    }
  },
  methods: {
    setOpenModalPayment(newValue) {
      this.isOpenModalPayment = newValue;
    },
    setUserInfo({ passportId, phoneNumber }) {
      this.passportId = passportId;
      this.phoneNumber = phoneNumber;
    },

    handleOpenModalQRPay(value) {
      this.isOpenModalQRPay = value;
    },
    async checkPhoneNumber() {
      const res = await AccountService.checkPhoneValidateRegister({
        phone: this.phoneNumber,
        passport: this.passportId
      });
      this.isPhoneExist = res.status === 200;
    },
    handleConfirm() {
      let data = {
        phone_number: this.phoneNumber
      };

      if (this.isUpdateEmail) {
        data.email = this.email;
      }

      if (this.getLoadByIframe && this.getParentInviteCode) {
        data.invite_code = this.getParentInviteCode;
      }

      let urlSearchParams = new URLSearchParams(window.location.search);
      Object.entries(data).forEach(([key, value]) => {
        urlSearchParams.set(key, value);
      });

      this.$router.replace(`${window.location.pathname}?${urlSearchParams.toString()}`);

      if (this.isPhoneExist) {
        this.$store.commit('setRedirectAfterLogin', null);
        this.$store.commit('setModalLogin', true);
      } else {
        this.$store.commit('setRedirectAfterLogin', null);
        this.$store.commit('setModalRegister', true);
      }
    },
    handleCloseModal() {
      // this.isPaymentSuccess = false;
      this.isUpdateEmail = false;
      this.$emit('hideModalQuickPayment');
    },
    handleUpdateEmail() {
      if (!this.paymentSuccessData.order_id) return;
      this.isLoadingUpdateEmail = true;
      OrderService.updateMailForOrder(this.paymentSuccessData.order_id, {
        mail: this.email
      })
        .then((status) => {
          if (status === 204) {
            this.isUpdateEmail = true;
          } else {
            throw new Error('Nhận thông tin email không thành công. Vui lòng thử lại.');
          }
        })
        .catch((error) => {
          Noti.fire({
            icon: 'error',
            title: error?.message || error.response?.data?.data?.message || '',
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.isLoadingUpdateEmail = false;
        });
    },
    getPaymentQr() {
      try {
        if (!this.isConnectedSocket) {
          Swal.fire({
            icon: 'warning',
            title: 'Lỗi gián đoạn kết nối, vui lòng thử lại',
            showConfirmButton: true,
            confirmButtonText: 'Thử lại'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
          return;
        }

        this.$emit('handleQuickPayment');
      } catch (error) {
        this.setOpenModalPayment(false);
        Noti.fire({
          icon: 'error',
          title: `${error?.message || error?.response?.data?.data?.message || 'Lỗi lấy mã Qr !'}`,
          showConfirmButton: false,
          timer: 3000
        });
      }
    }
  },
  mounted() {},
  watch: {
    isOpenModalPayment: function(newValue) {
      if (newValue) {
        if (this.comPassportId !== this.passportId || this.comPhoneNumber !== this.phoneNumber) {
          this.setUserInfo({
            passportId: this.comPassportId,
            phoneNumber: this.comPhoneNumber
          });
        }

        if (!this.isPaymentSuccess) {
          this.getPaymentQr();
        } else {
          if (this.parentUserInfo.email) {
            this.email = this.parentUserInfo.email;
            this.$nextTick(function() {
              this.$validator.validateAll();
            });
          }
        }
      }
    },
    phoneNumber: function(newValue) {
      if (newValue) {
        this.checkPhoneNumber();
      }
    },
    'nearestPeriodChoose.lottery_time': function(newValue) {
      const expiredTime = (new CustomDate(newValue).getTime() - CustomDate.now()) / 1000;
      this.countDownExpiredQrCode = expiredTime;
      if (this.timerId) {
        clearInterval(this.timerId);
      }

      this.timerId = setInterval(() => {
        const expiredTime = (new CustomDate(newValue).getTime() - CustomDate.now()) / 1000;
        this.countDownExpiredQrCode = expiredTime;
      }, 1000);
    },
    isPaymentSuccess: function(newValue) {
      if (newValue) {
        if (this.isOpenModalPayment) {
          if (this.parentUserInfo.email) {
            this.email = this.parentUserInfo.email;
            this.$nextTick().then(() => {
              this.$validator.validateAll();
            });
          }
        } else {
          this.isOpenModalPayment = true;
        }
      }
    },
    calcSafeTime: function(newValue) {
      if (newValue < 0 && this.isOpenModalPayment && !this.isPaymentSuccess) {
        this.$emit('handleTimeoutQuickPayment');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modalPaymentSuccess {
  &__btnUpdate {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: $primaryColor;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #fff;
  }

  &__description {
    width: 75%;
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 15px;

    img {
      margin-bottom: 10px;
    }

    p {
      // margin-bottom: 5px;
    }

    button.btn {
      width: 100%;
      height: 40px;
      border-radius: 3px;
      background-color: #00509d;
      color: #fff;
    }
  }
}
.boxTicketHeader {
  margin-bottom: 5px;
}
.boxTicketRecipientInfo {
  padding: 11px 0px;
  .recipientInfo {
    flex-direction: row;
  }
}

.groupQrPaymnet {
  .totalPayment {
    margin-bottom: 3px;
  }
  &__left {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .qrCode {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  &__right {
    padding: 0 20px 20px;

    .tutorialQr {
      ul {
        list-style: decimal;
        margin-left: 15px;
        margin-bottom: 15px;
        li {
          padding-left: 10px;
          line-height: 1.73;
        }
      }
      .listBankSupport {
        color: $colorBlue;
        cursor: pointer;
        padding-left: 25px;
      }
    }
  }
}

.totalPayment {
  font-weight: bold;
  font-size: 25px;
  color: $colorBlue;
}

.loadingBox {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeholders-qr {
  max-width: 80%;
  height: calc(244px * 0.8);
  margin: 0 auto;
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .modalPaymentSuccess {
    &__btnUpdate {
      width: 182px;
      margin: 0 auto;
    }
  }

  .groupQrPaymnet {
    &__left {
      max-width: 272px;
    }
  }
}
.borderBottomDash {
  border-bottom: dashed 1px #d8d8d8;
  margin-bottom: 20px;
}

.btnUpdateEmail {
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 600;
  min-height: 40px;
  font-size: 0.9375rem;
  color: #fff;
  background-color: $primaryColor;
  width: 100%;
  &:hover {
    background-color: $primaryColorDarkest;
  }
}

.btnDownloadQr {
  width: 155px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;

  margin-bottom: 20px;
  border-radius: 3px;

  font-weight: 600;
  font-size: 0.9375rem;
  color: $colorBlue;
  text-transform: initial;

  background-color: #e5edf5;
  &:hover {
    background-color: darken(#e5edf5, 10%);
  }

  & > img {
    margin-right: 10px;
  }
}

.buttonShareFb {
  height: 40px;
  border-radius: 3px;
  background-color: #00509d;
  color: #fff;
}
</style>

<style lang="scss">
.customConfirmBtn {
  button.actionGroup__btn--confirm {
    max-width: 100% !important;
  }
}

.customPaymentSuccess {
  button.actionGroup__btn--confirm {
    color: $colorBlue;
    background-color: #e5edf5;
    &:hover {
      color: $colorBlue;
      background-color: darken(#e5edf5, 10%);
    }
  }
}

.modalPaymentCustomNew {
  max-width: 731px !important;
}
.modalPaymentSuccessCustom {
  max-width: 344px !important;
}
</style>
