<template>
  <div>
    <component
      :tutorialGame="tutorialGame"
      :is="component"
      :gameId="gameId"
      :nameGame="namePage"
      :bannerByGame="bannerByGame"
      @openModalConfirmPayment="openModalConfirmPayment"
      ref="gameRef"
      :shipData="shipData"
      :paymentData="isPaymentIntegration ? paymentDataIntegration : paymentData"
      :paymentDataQuickBuy="paymentDataQuickBuy"
      :paymentDataIntegration="paymentDataIntegration"
      :paymentDataPartner="paymentDataPartner"
      :gameHandler="handler"
      @handlerSendDataBuyNow="handlerSendDataBuyNow"
      @handlerSendDataBuyNowTraditionGame="handlerSendDataBuyNowTraditionGame"
      :nearestPeriodChoose="nearestPeriodChoose"
      @updateNearestPeriodChoose="updateNearestPeriodChoose"
      :hiddenQuickBuy="hiddenQuickBuy"
      @handleTimeoutQuickPayment="handleTimeoutQuickPayment"
      :gameData="gameData"
      :isDisabledQuickBuy="isDisabledQuickBuy"
      @handleConfirmPayment="handleConfirmPayment"
      @setErrorPeriods="setErrorPeriods"
      :errorPeriods="errorPeriods"
      @setModalConfirmPeriod="setModalConfirmPeriod"
      @setIsShowContinue="setIsShowContinue"
      @setSaveStateQuickBuy="setSaveStateQuickBuy"
      @handleCloseConfirmPayment="handleCloseConfirmPayment"
      :isLoadingPayment="isLoadingPayment"
      @mountedComponent="handleMountedGame"
      :countDownTime="getTimerByHandler(gameType)"
    ></component>

    <ConfirmPayment
      ref="confirmPayment"
      :paymentConfirmData="paymentConfirmData"
      :shipData="shipData"
      :paymentData="paymentData"
      :namePage="namePage"
      :isEnoughMoney="isEnoughMoney"
      :isLoading="isLoadingPayment"
      @handleConfirmMessagePayment="handleConfirmMessagePayment"
      :isUseRewardBalance="isUseRewardBalance"
    />

    <ModalQuickBuyInfo ref="modalQuickBuyInfo" @handleNextStep="handleNextStep" />
    <ModalQuickPayment
      :listPaymentMethod="getListPaymentMethod"
      ref="modalQuickPayment"
      :handler="handler"
      :paymentConfirmData="paymentConfirmData"
      :nearestPeriodChoose="nearestPeriodChoose"
      :paymentDataQuickBuy="paymentDataQuickBuy"
      :gameData="gameData"
      @handleQuickPayment="handleQuickPayment"
      :isPaymentSuccess="isPaymentSuccess"
      :paymentSuccessData="paymentSuccessData"
      @handleTimeoutQuickPayment="handleTimeoutQuickPayment"
      :isLoadingQrPayment="isLoadingPayment"
      :paymentQrCode="paymentQrCode"
      @hideModalQuickPayment="hideModalQuickPayment"
    />
    <CommonModal
      title="Thông báo"
      :isOpen="isOpenModalConfirmPeriod"
      cancleTextBtn="Chọn lại"
      confirmTextBtn="Mua các kỳ còn lại"
      @updateOpen="setModalConfirmPeriod"
      @handleConfirm="handleConfirmContinue"
      @handleCancle="saveStateQuickBuy = false"
      :isCloseWhenConfirm="true"
      :isConfirmBtn="isShowContinue"
    >
      <div v-if="errorPeriods.length">
        <div v-if="errorPeriods.length > 1">
          <div>
            Số lượng vé đã đặt trước cho cách kỳ đã vượt quá giới hạn vé. Vui lòng chọn mua kỳ khác.
          </div>
          <ul class="mt-2">
            <li class="mt-1" v-for="(item, index) in errorPeriods" :key="index">
              {{ `Kỳ #${item.period_no} (${formatDate(item.lottery_time, 'DD/MM/YYYY HH:mm')})` }}
            </li>
          </ul>
        </div>
        <div v-else>
          {{
            `Số lượng vé đã đặt trước cho kỳ #${errorPeriods[0].period_no} vượt quá giới hạn. Vui lòng chọn mua kỳ khác.`
          }}
        </div>
      </div>
    </CommonModal>
  </div>
</template>
<script>
import UIBanner from '@/components/UI/Banner/UIBanner';

import PaymentAppService from '@/api/payment';
import OrderAppService from '../../api/order';
import { ErrorOrderService } from '../../api/mock/errors';
import { Noti } from '../../main';
import { DEFAUL_PAYMENT_METHOD, DEFAUL_SHIPPING_INDEX, DEFAUL_PAYMENT_METHOD_QUICK_BUY } from '@/common/global';
import ModalQuickBuyInfo from '@/components/Game/ModalQuickBuyInfo';
import ModalQuickPayment from '@/components/Game/ModalQuickPayment';
import ConfirmPayment from '@/components/Game/ConfirmPayment';
import CommonModal from '@/components/CommonModal';

import {
  APP_ENV,
  BASE_URL,
  eventTypeMessage,
  FEATURE_QUICK_BUY,
  PAYMENT_METHOD_DIVINE,
  SAFE_TIME_QR,
  SHAREFB_TYPE,
  SOCKET_EVENT,
  TIME_OUT_SAVE_SOCKET_ID
} from '../../common/global';
import { mapGetters } from 'vuex';

export default {
  components: {
    TraditionalGame: () => import('./GameDetail/TraditionalGame'),
    UIBanner,
    ConfirmPayment,
    ModalQuickBuyInfo,
    ModalQuickPayment,
    CommonModal
  },
  props: {
    gameId: {
      type: Number,
      required: true
    },
    bannerByGame: {
      type: Object
    },
    namePage: {
      type: String,
      required: true
    },
    component: {
      type: String,
      required: true
    },
    keyBannerDesktop: {
      type: String
    },
    keyBannerMobile: {
      type: String
    },
    handler: {
      type: String
    }
  },
  data() {
    return {
      shipData: {
        id: '',
        shipFee: 0,
        shipRate: 0
      },

      paymentData: {
        id: '',
        paymentFee: 0,
        paymentRate: 0
      },
      paymentDataQuickBuy: {
        id: '',
        paymentFee: 0,
        paymentRate: 0
      },
      paymentDataIntegration: {
        id: '',
        paymentFee: 0,
        paymentRate: 0
      },
      paymentDataPartner: {
        id: '',
        paymentFee: 0,
        paymentRate: 0
      },

      paymentConfirmData: {
        transactionFee: 0,
        resultPrice: 0,
        listProduct: []
      },
      isLoadingPayment: false,
      nearestPeriodChoose: {},
      isQuickBuy: '',
      isPaymentSuccess: false,
      paymentSuccessData: {},
      hiddenQuickBuy: !+FEATURE_QUICK_BUY,
      isDisabledQuickBuy: false,
      paymentQrCode: '',
      holdState: false,
      isOpenModalConfirmPeriod: false,
      errorPeriods: [],
      isShowContinue: false,
      saveStateQuickBuy: false,
      SHAREFB_TYPE,
      fillTicket: false,
      isHoldActionOrder: false,
      isLoadingShipping: false,
      timerBuyNow: null
    };
  },
  computed: {
    isBuyTogether() {
      return this.$route.meta.isBuyTogether;
    },
    isBuyTogetherDetail() {
      return this.$route.meta.isBuyTogetherDetail;
    },
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    },
    isTerra() {
      return this.$store.getters.getterIsTerraMode;
    },
    listGame() {
      return this.$store.getters.getterListGame;
    },
    isKeno() {
      return this.gameId === this.$store.getters.getterGameId['keno'];
    },
    isPower() {
      return this.gameId === this.$store.getters.getterGameId['power'];
    },
    isMega() {
      return this.gameId === this.$store.getters.getterGameId['mega'];
    },
    rewardGameKenoPowerMega() {
      let data =
        Object.values(this.listGame).length > 0 &&
        Object.values(this.listGame).filter((p) => p.id === this.gameId)[0].reward;
      return typeof data == 'number' ? this.formatPrice(data) : '';
    },
    reward() {
      let prize = {
        keno: () => this.rewardGameKenoPowerMega,
        power: () => this.rewardGameKenoPowerMega,
        mega: () => this.rewardGameKenoPowerMega,
        max3d: () => 'x100 lần',
        'max3d+': () => 'x100.000 lần',
        max3dpro: () => 'x100.000 lần',
        max4d: () => 'x1.500 lần',
        xsmn: () => '2 tỉ'
      };
      return prize[this.handler].call(this);
    },
    tutorialGame() {
      let actions = {
        // keno: () => '/luat-choi/luat-choi-keno',
        // power: () => '/luat-choi/luat-choi-power',
        // mega: () => '/luat-choi/luat-choi-mega',
        // max3d: () => '/luat-choi/luat-choi-max3d',
        // 'max3d+': () => '/luat-choi/luat-choi-max3d+',
        // max3dpro: () => '/luat-choi/luat-choi-max3dpro',
        // max4d: () => '/luat-choi/luat-choi-max4d',
        xsmn: () => '/luat-choi/luat-choi-xsmn'
        // buy_group: () => 'huong-dan-nhom-mua-chung'
      };
      let currentHandler = this.handler;
      // huong-dan-nhom-mua-chung
      if (this.isBuyTogether || this.isBuyTogetherDetail) {
        currentHandler = 'buy_group';
      }
      return actions[currentHandler].call(this);
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    isEnoughMoney() {
      return (
        this.isPartnerMode || this.isPlayTrialModeMobile || this.depositBalance >= this.paymentConfirmData.resultPrice
      );
    },
    isUseRewardBalance() {
      return (
        !this.isPlayTrialModeMobile &&
        this.depositBalance < this.paymentConfirmData.resultPrice &&
        this.depositBalance + this.rewardBalance >= this.paymentConfirmData.resultPrice
      );
    },
    getListPaymentMethod: {
      get() {
        return this.$store.getters.getterListPaymentMethod;
      },
      set(newVal) {
        this.$store.commit('setListPaymentMethod', newVal);
      }
    },
    gameData() {
      return this.listGame[this.gameId];
    },
    referCode() {
      return this.$store.getters.getterUserInviteCode;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    parentUserInfo() {
      return this.$store.state.parentUserInfo;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    currentTransaction: {
      get() {
        return this.$store.getters.getterCurrentTransaction;
      },
      set(newVal) {
        this.$store.commit('setCurrentTransaction', newVal);
      }
    },
    isConnectedSocket() {
      return this.$store.getters.getterIsConnectedSocket;
    },
    listGameHandlerById() {
      return this.convertArrayToObject(Object.values(this.$store.getters.getterListGameHandler), 'id');
    },
    calcSafeTime() {
      const { metadata } = this.gameData;
      const metadataObj = JSON.parse(metadata);
      return SAFE_TIME_QR + metadataObj?.count_down || 0;
    },
    isOpenPopupWaitingPayment: {
      get() {
        return this.$store.getters.getterIsOpenPopupWaitingPayment;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupWaitingPayment', newVal);
      }
    },
    listPeriodFullCapacity: {
      get() {
        return this.$store.getters.getterListPeriodFullCapacity;
      },
      set(value) {
        this.$store.commit('setListPeriodFullCapacity', value);
      }
    },
    // getTimerByHandler() {
    //   return function(handler) {
    //     switch (handler) {
    //       case 'mega':
    //         return this.timerToString(this.$store.state.CD.megaHomeTimer);

    //       case 'power':
    //         return this.timerToString(this.$store.state.CD.powerHomeTimer);

    //       case 'max3d':
    //       case 'max3d+':
    //         return this.timerToString(this.$store.state.CD.max3DHomeTimer);

    //       case 'max3dpro':
    //         return this.timerToString(this.$store.state.CD.max3DProHomeTimer);

    //       case 'max4d':
    //         return this.timerToString(this.$store.state.CD.max4DHomeTimer);

    //       case 'xsmn':
    //         return this.timerToString(this.$store.state.CD.xsmnHomeTimer);

    //       default:
    //         return '';
    //     }
    //   };
    // },
    getTimerByHandler() {
      return function(handler) {
        switch (handler) {
          case 'mega':
            return this.$store.state.CD.megaHomeTimer;

          case 'power':
            return this.$store.state.CD.powerHomeTimer;

          case 'max3d':
          case 'max3d+':
            return this.$store.state.CD.max3DHomeTimer;

          case 'max3dpro':
            return this.$store.state.CD.max3DProHomeTimer;

          case 'max4d':
            return this.$store.state.CD.max4DHomeTimer;

          case 'xsmn':
            return this.$store.state.CD.xsmnHomeTimer;

          default:
            return '';
        }
      };
    },
    gameType() {
      return this.listGameHandlerById[this.gameId].handler;
    },
    // hiddenQuickBuy() {
    //   return !this.loggedIn;
    // },
    // isPartnerMode() {
    //   return !!this.getterPartnerData?.id;
    // },
    // ...mapGetters('partner', ['getterPartnerData']),
    ...mapGetters(['getterDataRequestPayment', 'getterBuyTicketAgainData'])
  },
  sockets: {
    [SOCKET_EVENT.ORDER_PAYMENT]: function(data) {
      const dataParse = JSON.parse(data);
      if (dataParse.success && dataParse.hash && dataParse.hash === this.currentTransaction) {
        const { phone, name } = this.loggedIn ? this.userInfo : this.parentUserInfo;
        let source = this.$store.getters.getterAffNetwork || '';
        this.sendMessageToParent(eventTypeMessage.BUY_TICKET_SUCCESS, {
          order_id: dataParse.gateway_order_id,
          partner: source,
          user_name: name || '',
          user_phone: phone || '',
          refer_code: this.referCode || source,
          money: dataParse.amount,
          lottery_type: dataParse.game,
          display_order_id: dataParse.display_order_id,
          ticket_url: dataParse.display_ticket_list?.length
            ? `${BASE_URL}/tai-khoan/lich-su-dat-mua/${dataParse.display_ticket_list?.[0]}`
            : ''
        });

        this.$store.commit('setDataRequestPayment', {
          gift_type: SHAREFB_TYPE.GIFT,
          gift_code: dataParse.gift_code,
          gift_message: dataParse.gift_message,
          order_id: dataParse.order_id
        });

        this.isOpenPopupWaitingPayment = false;
        if (this.accountId) {
          this.$refs.modalQuickPayment.setOpenModalPayment(false);
          this.handleSetModalPaymentSuccess(true);
        } else {
          this.isPaymentSuccess = true;
          this.paymentSuccessData = { order_id: dataParse.order_id, display_order_id: dataParse.display_order_id };
        }
        // Clear ticket
        this.$refs.gameRef.clearTicketAfterPayment();
        if (this.$store.getters.getterInfoOauth2 && this.$store.getters.getterInfoOauth2._aff_network === 'saveextra') {
          this.$store.commit('removeInfoOauth2');
        }
      }
    }
  },
  methods: {
    // GET PAYMENT DATA

    async getListShipping() {
      this.isLoadingShipping = true;
      await PaymentAppService.getListShipping().then((resp) => {
        const listShipping = resp.data.data.filter((ship) => ship.status === 1);
        this.shipData.id = listShipping[DEFAUL_SHIPPING_INDEX].id;
        this.shipData.shipFee = listShipping[DEFAUL_SHIPPING_INDEX].fee;
        this.shipData.shipRate = listShipping[DEFAUL_SHIPPING_INDEX].rate;
      });
      this.isLoadingShipping = false;
    },
    async getPaymentMethods() {
      try {
        let listPaymentMethod = [];
        if (this.getListPaymentMethod.length) {
          listPaymentMethod = [...this.getListPaymentMethod];
        } else {
          const res = await PaymentAppService.getPaymentMethods();
          this.getListPaymentMethod = res.data.data;
          listPaymentMethod = res.data.data;
        }

        let normalPaymentIndex = -1;
        let quickBuyPaymentIndex = -1;
        let integrationPaymentIndex = -1;

        this.getListPaymentMethod.forEach(({ handler }, index) => {
          switch (handler) {
            case DEFAUL_PAYMENT_METHOD:
              normalPaymentIndex = index;
              break;
            case DEFAUL_PAYMENT_METHOD_QUICK_BUY:
              quickBuyPaymentIndex = index;
              break;
            case PAYMENT_METHOD_DIVINE:
              integrationPaymentIndex = index;
              break;
            default:
              break;
          }
        });

        if (normalPaymentIndex >= 0) {
          this.paymentData.id = listPaymentMethod[normalPaymentIndex].id;
          this.paymentData.paymentFee = listPaymentMethod[normalPaymentIndex].fee;
          this.paymentData.paymentRate = listPaymentMethod[normalPaymentIndex].rate;
        }

        if (quickBuyPaymentIndex >= 0) {
          this.paymentDataQuickBuy.id = listPaymentMethod[quickBuyPaymentIndex].id;
          this.paymentDataQuickBuy.paymentFee = listPaymentMethod[quickBuyPaymentIndex].fee;
          this.paymentDataQuickBuy.paymentRate = listPaymentMethod[quickBuyPaymentIndex].rate;
        } else {
          this.hiddenQuickBuy = true;
        }

        if (integrationPaymentIndex >= 0) {
          this.paymentDataIntegration.id = listPaymentMethod[integrationPaymentIndex].id;
          this.paymentDataIntegration.paymentFee = listPaymentMethod[integrationPaymentIndex].fee;
          this.paymentDataIntegration.paymentRate = listPaymentMethod[integrationPaymentIndex].rate;
        }

        if (this.isPartnerMode) {
          const findData = this.getListPaymentMethod.find(
            (item) => item.id === this.getterPartnerData.default_payment_method
          );
          this.paymentDataPartner.id = findData?.id;
          this.paymentDataPartner.paymentFee = findData?.fee;
          this.paymentDataPartner.paymentRate = findData?.rate;
        }
      } catch (error) {
        console.error(error);
      }
    },

    openModalConfirmPayment(data) {
      const { transactionFee, resultPrice, listProduct, isQuickBuy } = data;
      // this.$refs.gameRef.handlerBuyNow('', true, false, true);
      this.paymentConfirmData = {
        transactionFee,
        resultPrice,
        listProduct
      };

      // if (this.isEnoughMoney || this.isUseRewardBalance) {
      //   this.isQuickBuy = '';
      //   this.$refs.gameRef.handlerBuyNow('', true);
      // } else {
      //   this.$router.push('/tai-khoan/nap-tien/');
      // }

      switch (true) {
        case isQuickBuy:
          this.$refs.modalQuickBuyInfo.setModalInfo(true);
          break;
        case this.isPaymentIntegration:
          this.handleIntegratePayment();
          break;
        // case this.isShowConfirmPayment && this.isUseRewardBalance:
        case this.isShowConfirmPayment && this.isEnoughMoney:
          this.isQuickBuy = '';
          this.$refs.gameRef.handlerBuyNow('', true);
          break;
        default:
          this.$refs.confirmPayment.setModalConfirmPayment(true);
          this.$refs.gameRef.handleEndLoadingPaymentKeno?.();
          break;
      }

      // if (isQuickBuy) {
      //   this.$refs.modalQuickBuyInfo.setModalInfo(true);
      // } else {
      //   if (this.isPaymentIntegration) {
      //     this.handleRequestPaymentToParent();
      //   } else {
      //     this.$refs.confirmPayment.setModalConfirmPayment(true);
      //   }
      // }
    },

    handleConfirmMessagePayment() {
      if (this.isEnoughMoney || this.isUseRewardBalance) {
        this.isQuickBuy = '';
        this.$refs.gameRef.handlerBuyNow('', true);
      } else {
        this.$router.push('/tai-khoan/nap-tien/');
      }
    },

    handleQuickPayment() {
      this.isQuickBuy = true;
      this.$refs.gameRef.handlerBuyNow('', true, false, true);
    },

    async handlerSendDataBuyNow(ticketData, periodData, cb, additionalData) {
      this.isLoadingPayment = true;
      if (this.timerBuyNow) {
        clearTimeout(this.timerBuyNow);
      }
      this.timerBuyNow = setTimeout(() => {
        if (this.isLoadingShipping) {
          this.isHoldActionOrder = true;

          return;
        }

        let source = this.$store.getters.getterAffNetwork || '';
        let tracking_id = this.$store.getters.getterAffSid || '';
        let dataPayment = {
          account_id: this.accountId || 0,
          shipping_fee_id: this.shipData.id,
          ticket_list: additionalData.ticket_list
            ? additionalData.ticket_list
            : [
                {
                  period: [...periodData],
                  game_id: this.gameId,
                  ticket: [...ticketData]
                }
              ],
          source,
          tracking_id,
          ...additionalData
        };

        if (additionalData?.order_type && additionalData?.order_type !== 'keno_invest') {
          dataPayment.ticket_list[0].order_type = additionalData.order_type;
        }

        const { id } = this.isPaymentIntegration
          ? this.paymentDataIntegration
          : this.isQuickBuy
          ? this.paymentDataQuickBuy
          : this.paymentData;

        dataPayment.payment_id = id;

        if (this.isPaymentIntegration || this.isQuickBuy) {
          if (!this.$socket.id) {
            this.holdState = true;
            return;
          } else {
            this.holdState = false;
          }

          dataPayment.client_id = this.$socket.id;
          if (!this.loggedIn) {
            dataPayment.receiver_id = this.$refs.modalQuickBuyInfo.passportId || undefined;
            dataPayment.receiver_phone = this.$refs.modalQuickBuyInfo.phoneNumber;
          }
        }

        if (this.isPaymentIntegration) {
          let { lottery_time } = this.nearestPeriodChoose;
          if (lottery_time) {
            const lotteryTimeDate = new CustomDate(lottery_time);
            // add 5s to ignore latency when check expire in BE
            dataPayment.expired_at = new CustomDate(lotteryTimeDate - this.calcSafeTime * 1000 + 5000).toISOString();
          }
        }

        if (this.isPlayTrialModeMobile) {
          this.$refs.confirmPayment.setModalConfirmPayment(false);
          this.handleSetModalPaymentSuccess(true, dataPayment);
          this.isLoadingPayment = false;
          return;
        }

        if (additionalData.is_buy_group) {
          delete dataPayment.ticket_list;
        }
        if (this.isPartnerMode) {
          dataPayment.payment_id = this.getterPartnerData.default_payment_method;
          dataPayment.shipping_fee_id = this.getterPartnerData.default_shipping_method;
          dataPayment.client_id = this.$socket.id;
          dataPayment.extra_data = {
            return_url: '/tai-khoan/lich-su-dat-mua/',
            rebuy_url: this.$route.path
          };
        }

        this.isLoadingPayment = true;
        if (this.isPaymentIntegration || this.isQuickBuy || this.isPartnerMode) {
          OrderAppService.postOrderQuickBuy(dataPayment)
            .then((res) => {
              if (res.status) {
                if (this.isPartnerMode) {
                  const [, dataParams] = res.data.url.split('/payment?data=');
                  // this.$router.push(`/payment?data=${encodeURIComponent(dataParams)}&url_back=${this.$route.path}`);
                  window.location = `/payment?data=${encodeURIComponent(dataParams)}&url_back=${this.$route.path}`;
                  return;
                }

                this.currentTransaction = res.data.hash;
                this.$store.commit('setDataRequestPayment', {
                  ...dataPayment,
                  gift_type: SHAREFB_TYPE.GIFT,
                  ...res.data,
                  is_buy_group: additionalData.is_buy_group
                });

                if (this.isPaymentIntegration) {
                  const orderInfo = {
                    period: dataPayment.ticket_list[0]?.period[0],
                    ticket_list: dataPayment.ticket_list[0]?.ticket.map(
                      ({ name, price, product_id, ticket_info, ticket_custom }) => {
                        let resultData = {
                          name,
                          price,
                          product_name: this.getProductName({
                            game_id: gameId,
                            product_id
                          })
                        };

                        let gameId = dataPayment.ticket_list[0].game_id;
                        let { handler: gameHandler, name: gameName } = this.listGameHandlerById[gameId];

                        if (ticket_custom) {
                          resultData.ticket_info = [ticket_custom];
                          resultData.product_name = `${gameName} ${this.getProductName({
                            game_id: gameId,
                            product_id
                          })}`;
                        } else {
                          if (['keno', 'power', 'mega'].includes(gameHandler)) {
                            resultData.product_name = `${gameName} ${this.getProductName({
                              game_id: gameId,
                              product_id
                            })}`;

                            resultData.ticket_info = ticket_info;
                          } else {
                            resultData.product_name = this.getProductName({
                              game_id: gameId,
                              product_id
                            });

                            if (gameHandler === 'max3d+') {
                              resultData.ticket_info = [
                                ticket_info.slice(0, 3).join(''),
                                ticket_info.slice(3).join('')
                              ];
                            } else if (gameHandler === 'max4d') {
                              let productHandler = this.getProductHandler({
                                game_id: gameId,
                                product_id
                              });

                              if (productHandler.includes('head')) {
                                resultData.ticket_info = [`X${ticket_info.join('')}`];
                              } else if (productHandler.includes('tail')) {
                                resultData.ticket_info = [`${ticket_info.join('')}X`];
                              } else {
                                resultData.ticket_info = [ticket_info.join('')];
                              }
                            }
                          }
                        }

                        return resultData;
                      }
                    )
                  };
                  this.sendMessageToParent(eventTypeMessage.REQUEST_PAYMENT, {
                    ...res.data,
                    expired_at: dataPayment.expired_at,
                    order_info: orderInfo
                  });
                  this.setCookieInIframe('old_socket_id', this.$socket.id, TIME_OUT_SAVE_SOCKET_ID);
                  if (!this.loggedIn) {
                    this.setCookieInIframe('save_order_receiver_id', dataPayment.receiver_id, TIME_OUT_SAVE_SOCKET_ID);
                    this.setCookieInIframe(
                      'save_order_receiver_phone',
                      dataPayment.receiver_phone,
                      TIME_OUT_SAVE_SOCKET_ID
                    );
                  }
                } else {
                  this.paymentQrCode = res.data.qr_code;
                  if (APP_ENV !== 'production') {
                    const orderCode = res.data?.qr_code?.match(/(?=TL)(.*)(?=0315WeScan)/g)?.[0];
                    orderCode && console.error(orderCode);
                  }
                }
              } else {
                throw '';
              }
            })
            .catch((e) => {
              this.$refs.modalQuickPayment.setOpenModalPayment(false);
              let code = e.response?.data?.data?.code;
              let error = ErrorOrderService.find((p) => p.key === code);
              if (!error) {
                Noti.fire({
                  icon: 'error',
                  title: 'Thanh toán lỗi, Vui lòng thanh toán lại',
                  showConfirmButton: false,
                  timer: 3000
                });
                return;
              }

              Noti.fire({
                icon: 'error',
                title: `${error.message}`,
                showConfirmButton: false,
                timer: 3000
              });
            })
            .finally(() => {
              this.isLoadingPayment = false;
              this.isQuickBuy = '';
            });
          return;
        }

        if (this.isUseRewardBalance) {
          dataPayment.use_withdraw_amount = true;
        }

        let OrderRequest = OrderAppService.postOrderV2;

        if (additionalData.is_buy_group) {
          OrderRequest = OrderAppService.postGroupOrder;
        }

        OrderRequest(dataPayment)
          .then((resp) => {
            if (resp.data.status) {
              this.$refs.confirmPayment.setModalConfirmPayment(false);

              this.$store.commit('setDataRequestPayment', {
                ...dataPayment,
                gift_type: SHAREFB_TYPE.GIFT,
                ...resp.data.data,
                is_buy_group: additionalData.is_buy_group
              });

              this.handleSetModalPaymentSuccess(true);

              const { phone, name } = this.loggedIn ? this.userInfo : this.parentUserInfo;

              this.sendMessageToParent(eventTypeMessage.BUY_TICKET_SUCCESS, {
                ...resp.data.data,
                partner: source,
                user_name: name || '',
                user_phone: phone || '',
                refer_code: this.referCode || source,
                money: this.paymentConfirmData.resultPrice,
                lottery_type: this.gameData.name
              });

              if (typeof cb === 'function') {
                cb();
              }
              if (
                this.$store.getters.getterInfoOauth2 &&
                this.$store.getters.getterInfoOauth2._aff_network === 'saveextra'
              ) {
                this.$store.commit('removeInfoOauth2');
              }
              this.refreshBalances();
            }
          })
          .catch((e) => {
            let { code, periods } = e.response.data.data || {};

            if (code === '006.001.028') {
              const currentListPeriodFullCapacity = new Set(this.listPeriodFullCapacity);
              periods.forEach((item) => {
                currentListPeriodFullCapacity.add(+item);
              });

              this.listPeriodFullCapacity = [...currentListPeriodFullCapacity];

              const currentPeriod = this.$refs.gameRef.actionActiveTimeDial;

              const newPeriod = currentPeriod.filter((item) => !this.listPeriodFullCapacity.includes(item.id));

              if (newPeriod.length) {
                this.$refs.gameRef.updateActiveTimeDial(newPeriod);
              } else {
                this.$refs.gameRef.updateActiveTimeDial([
                  this.$refs.gameRef.listTimeDial.filter((item) => !this.listPeriodFullCapacity.includes(item.id))[0]
                ]);
              }
              this.$refs.confirmPayment.setModalConfirmPayment(false);

              this.errorPeriods = currentPeriod.filter((item) => this.listPeriodFullCapacity.includes(item.id));

              if (dataPayment.ticket_list[0].period.length === this.errorPeriods.length) {
                this.isShowContinue = false;
              } else {
                this.isShowContinue = true;
              }

              this.setModalConfirmPeriod(true);

              return;
            }
            let error = ErrorOrderService.find((p) => p.key === code);
            if (!error) {
              Noti.fire({
                icon: 'error',
                title: 'Thanh toán lỗi, Vui lòng thanh toán lại',
                showConfirmButton: false,
                timer: 3000
              });
              return;
            }

            Noti.fire({
              icon: 'error',
              title: `${error.message}`,
              showConfirmButton: false,
              timer: 3000
            });
          })
          .finally(() => {
            this.isLoadingPayment = false;
          });
      }, 300);
    },

    async handlerSendDataBuyNowTraditionGame(ticketList, cb, additionalData) {
      let source = this.$store.getters.getterAffNetwork || '';
      let tracking_id = this.$store.getters.getterAffSid || '';
      let dataPayment = {
        account_id: this.accountId || 0,
        shipping_fee_id: this.shipData.id,
        ticket_list: ticketList,
        source,
        tracking_id,
        ...additionalData
      };

      const { id } = this.isPaymentIntegration
        ? this.paymentDataIntegration
        : this.isQuickBuy
        ? this.paymentDataQuickBuy
        : this.paymentData;

      dataPayment.payment_id = id;

      if (this.isPaymentIntegration || this.isQuickBuy) {
        if (!this.$socket.id) {
          this.holdState = true;
          return;
        } else {
          this.holdState = false;
        }

        dataPayment.client_id = this.$socket.id;
        if (!this.loggedIn) {
          dataPayment.receiver_id = this.$refs.modalQuickBuyInfo.passportId || undefined;
          dataPayment.receiver_phone = this.$refs.modalQuickBuyInfo.phoneNumber;
        }
      }

      if (this.isPaymentIntegration) {
        let { lottery_time } = this.nearestPeriodChoose;
        if (lottery_time) {
          const lotteryTimeDate = new CustomDate(lottery_time);
          // add 5s to ignore latency when check expire in BE
          dataPayment.expired_at = new CustomDate(lotteryTimeDate - this.calcSafeTime * 1000 + 5000).toISOString();
        }
      }

      if (this.isPlayTrialModeMobile) {
        this.$refs.confirmPayment.setModalConfirmPayment(false);
        this.handleSetModalPaymentSuccess(true, dataPayment);
        this.isLoadingPayment = false;
        return;
      }

      this.isLoadingPayment = true;
      if (this.isPaymentIntegration || this.isQuickBuy) {
        OrderAppService.postOrderQuickBuy(dataPayment)
          .then((res) => {
            if (res.status) {
              this.currentTransaction = res.data.hash;
              this.$store.commit('setDataRequestPayment', {
                ...dataPayment,
                gift_type: SHAREFB_TYPE.GIFT,
                ...res.data
              });

              if (this.isPaymentIntegration) {
                const orderInfo = {
                  period: dataPayment.ticket_list[0]?.period[0],
                  ticket_list: dataPayment.ticket_list[0]?.ticket.map(
                    ({ name, price, product_id, ticket_info, ticket_custom }) => {
                      let resultData = {
                        name,
                        price,
                        product_name: this.getProductName({
                          game_id: gameId,
                          product_id
                        })
                      };

                      let gameId = dataPayment.ticket_list[0].game_id;
                      let { handler: gameHandler, name: gameName } = this.listGameHandlerById[gameId];

                      if (ticket_custom) {
                        resultData.ticket_info = [ticket_custom];
                        resultData.product_name = `${gameName} ${this.getProductName({
                          game_id: gameId,
                          product_id
                        })}`;
                      } else {
                        if (['keno', 'power', 'mega'].includes(gameHandler)) {
                          resultData.product_name = `${gameName} ${this.getProductName({
                            game_id: gameId,
                            product_id
                          })}`;

                          resultData.ticket_info = ticket_info;
                        } else {
                          resultData.product_name = this.getProductName({
                            game_id: gameId,
                            product_id
                          });

                          if (gameHandler === 'max3d+') {
                            resultData.ticket_info = [ticket_info.slice(0, 3).join(''), ticket_info.slice(3).join('')];
                          } else if (gameHandler === 'max4d') {
                            let productHandler = this.getProductHandler({
                              game_id: gameId,
                              product_id
                            });

                            if (productHandler.includes('head')) {
                              resultData.ticket_info = [`X${ticket_info.join('')}`];
                            } else if (productHandler.includes('tail')) {
                              resultData.ticket_info = [`${ticket_info.join('')}X`];
                            } else {
                              resultData.ticket_info = [ticket_info.join('')];
                            }
                          }
                        }
                      }

                      return resultData;
                    }
                  )
                };
                this.sendMessageToParent(eventTypeMessage.REQUEST_PAYMENT, {
                  ...res.data,
                  expired_at: dataPayment.expired_at,
                  order_info: orderInfo
                });
                this.setCookieInIframe('old_socket_id', this.$socket.id, TIME_OUT_SAVE_SOCKET_ID);
                if (!this.loggedIn) {
                  this.setCookieInIframe('save_order_receiver_id', dataPayment.receiver_id, TIME_OUT_SAVE_SOCKET_ID);
                  this.setCookieInIframe(
                    'save_order_receiver_phone',
                    dataPayment.receiver_phone,
                    TIME_OUT_SAVE_SOCKET_ID
                  );
                }
              } else {
                this.paymentQrCode = res.data.qr_code;
                if (APP_ENV !== 'production') {
                  const orderCode = res.data?.qr_code?.match(/(?=TL)(.*)(?=0315WeScan)/g)?.[0];
                  orderCode && console.error(orderCode);
                }
              }
            } else {
              throw '';
            }
          })
          .catch((e) => {
            this.$refs.modalQuickPayment.setOpenModalPayment(false);
            let code = e.response?.data?.data?.code;
            let error = ErrorOrderService.find((p) => p.key === code);
            if (!error) {
              Noti.fire({
                icon: 'error',
                title: 'Thanh toán lỗi, Vui lòng thanh toán lại',
                showConfirmButton: false,
                timer: 3000
              });
              return;
            }

            Noti.fire({
              icon: 'error',
              title: `${error.message}`,
              showConfirmButton: false,
              timer: 3000
            });
          })
          .finally(() => {
            this.isLoadingPayment = false;
            this.isQuickBuy = '';
          });
        return;
      }

      if (this.isUseRewardBalance) {
        dataPayment.use_withdraw_amount = true;
      }

      OrderAppService.postOrderTraditional(dataPayment)
        .then((resp) => {
          if (resp.data.status) {
            this.$refs.confirmPayment.setModalConfirmPayment(false);

            this.$store.commit('setDataRequestPayment', {
              ...dataPayment,
              gift_type: SHAREFB_TYPE.GIFT,
              ...resp.data.data
            });

            this.handleSetModalPaymentSuccess(true);

            // const { phone, name } = this.loggedIn ? this.userInfo : this.parentUserInfo;

            // this.sendMessageToParent(eventTypeMessage.BUY_TICKET_SUCCESS, {
            //   ...resp.data.data,
            //   partner: source,
            //   user_name: name || '',
            //   user_phone: phone || '',
            //   refer_code: this.referCode || source,
            //   money: this.paymentConfirmData.resultPrice,
            //   lottery_type: this.gameData.name
            // });

            if (typeof cb === 'function') {
              cb();
            }
            if (
              this.$store.getters.getterInfoOauth2 &&
              this.$store.getters.getterInfoOauth2._aff_network === 'saveextra'
            ) {
              this.$store.commit('removeInfoOauth2');
            }
            this.refreshBalances();
          }
        })
        .catch((e) => {
          let { code, message: messageRes, periods } = e.response.data.data || {};

          if (code === '006.001.028') {
            const currentListPeriodFullCapacity = new Set(this.listPeriodFullCapacity);
            periods.forEach((item) => {
              currentListPeriodFullCapacity.add(+item);
            });

            this.listPeriodFullCapacity = [...currentListPeriodFullCapacity];

            const currentPeriod = this.$refs.gameRef.actionActiveTimeDial;

            const newPeriod = currentPeriod.filter((item) => !this.listPeriodFullCapacity.includes(item.id));

            if (newPeriod.length) {
              this.$refs.gameRef.updateActiveTimeDial(newPeriod);
            } else {
              this.$refs.gameRef.updateActiveTimeDial([
                this.$refs.gameRef.listTimeDial.filter((item) => !this.listPeriodFullCapacity.includes(item.id))[0]
              ]);
            }
            this.$refs.confirmPayment.setModalConfirmPayment(false);

            this.errorPeriods = currentPeriod.filter((item) => this.listPeriodFullCapacity.includes(item.id));

            if (dataPayment.ticket_list[0].period.length === this.errorPeriods.length) {
              this.isShowContinue = false;
            } else {
              this.isShowContinue = true;
            }

            this.setModalConfirmPeriod(true);

            return;
          }

          let error = ErrorOrderService.find((p) => p.key === code);
          if (!error) {
            Noti.fire({
              icon: 'error',
              title: messageRes || 'Thanh toán lỗi, Vui lòng thanh toán lại',
              showConfirmButton: false,
              timer: 3000
            });
            return;
          }

          Noti.fire({
            icon: 'error',
            title: `${error.message}`,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.isLoadingPayment = false;
        });
    },

    handleNextStep() {
      if (this.isPaymentIntegration) {
        this.handleIntegratePayment();
      } else {
        this.$refs.modalQuickPayment.setOpenModalPayment(true);
      }
      this.isPaymentSuccess = false;
    },
    updateNearestPeriodChoose(newValue) {
      this.nearestPeriodChoose = newValue;
      this.isDisabledQuickBuy = false;
    },
    handleTimeoutQuickPayment() {
      if (this.hiddenQuickBuy || !this.$refs.modalQuickPayment.isOpenModalPayment || this.isPaymentSuccess) {
        return;
      }
      this.$refs.modalQuickPayment.setOpenModalPayment(false);
      this.isDisabledQuickBuy = true;
      Noti.fire({
        icon: 'info',
        title: 'Mã thanh toán đã hết hạn !',
        showConfirmButton: false,
        timer: 3000
      });
    },
    handleRequestPaymentToParent() {
      this.sendMessageToParent(eventTypeMessage.REQUEST_PAYMENT, {
        order_id: 'xxx',
        amount: this.paymentConfirmData.resultPrice,
        checksum: 'xxx'
      });
    },
    handleSetModalPaymentSuccess(newVal, payload = {}) {
      this.$store.commit('setInfoFormValidated', true);
      this.$store.commit('setIsOpenModalPaymentSuccess', newVal);
      this.$store.commit('setPayloadGameTrial', payload);
    },
    handleIntegratePayment() {
      this.$refs.gameRef.handlerBuyNow('', true, false, true);
    },
    setModalConfirmPeriod(value) {
      this.isOpenModalConfirmPeriod = value;
    },
    handleConfirmContinue() {
      if (this.saveStateQuickBuy) {
        this.$refs.gameRef.handleQuickBuy();
      } else {
        this.$refs.gameRef.handlerBuyNow('', true);
      }
      this.saveStateQuickBuy = false;
      this.setModalConfirmPeriod(false);
    },
    handleConfirmPayment(value) {
      this.$refs.confirmPayment.setModalConfirmPayment(value);
    },
    setErrorPeriods(value) {
      this.errorPeriods = value;
    },
    setIsShowContinue(value) {
      this.isShowContinue = value;
    },
    setSaveStateQuickBuy(value) {
      this.saveStateQuickBuy = value;
    },
    handleCloseConfirmPayment() {
      if (this.$refs.confirmPayment?.isOpenModalConfirmPayment) {
        if (this.$refs.gameRef?.handleOpenPeriodExpired) {
          this.$refs.gameRef.handleOpenPeriodExpired();
        }

        this.$refs.confirmPayment.setModalConfirmPayment(false);
      }

      // gameRef
    },
    handleMountedGame() {
      if (this.fillTicket) {
        this.$refs.gameRef.handleFillTicket(this.getterBuyTicketAgainData);
      }
    },
    hideModalQuickPayment() {
      this.$refs.gameRef.handleEndLoadingPaymentKeno?.();
    }
  },
  mounted() {
    this.getListShipping();
    this.getPaymentMethods();
  },
  beforeDestroy() {
    this.currentTransaction = '';
  },
  watch: {
    listUserReceiver: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.checkFormDataMobile();
      }
    },
    isConnectedSocket: function(value) {
      if (value && this.holdState) {
        this.$refs.gameRef.handlerBuyNow('', true, false, true);
      }
    },
    isOpenModalConfirmPeriod: function(newValue) {
      if (!newValue) {
        this.errorPeriods = [];
        this.saveStateQuickBuy = false;
      }
    },
    accountId: function() {
      this.getListShipping();
    },
    isLoadingShipping(newValue) {
      if (!newValue && this.isHoldActionOrder) {
        this.$refs.gameRef.emitDataToConfirmPayment();
        this.isHoldActionOrder = false;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.uniqueName === 'deposit' && this.handler === 'keno') {
      this.$bus.$emit('saveGiftInfo');
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.meta.uniqueName === 'deposit' && vm.handler === 'keno') {
        vm.$store.commit('setIsSetSelectIdolData', true);
      } else {
        vm.$store.commit('setIsSetSelectIdolData', false);
      }
      const listFillTicket = [
        'ticket-result',
        'ket-qua-duoc-tang',
        'ket-qua-da-tang',
        'ket-qua-ky-quay',
        'don-mua',
        've-da-tang',
        've-duoc-tang',
        'ket-qua-do',
        'ket-qua-game'
      ];
      if (listFillTicket.includes(from.name)) {
        vm.fillTicket = true;
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('setIsSetSelectIdolData', false);
    next();
  }
};
</script>
<style lang="scss" scoped>
.groupInfoConfirm {
  // padding: 0 0.25rem;
  hr {
    margin: 10px 0 7.5px;
    border: solid 1px #cecece;
  }
  & > p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__total {
    span {
      font-weight: bold;
    }
  }
  &__warning {
    text-align: center;
    font-style: italic;
    color: #790505;

    margin-top: 20px;
  }

  &__numberTicket {
    font-size: 0.8125rem;
    font-style: italic;
  }
}
</style>
